.app {
  font-family: Arial, sans-serif;
}

.navbar {
  background: linear-gradient(135deg, #6e45e2, #88d3ce);
  padding: 15px;
}

/* Align logo to the left and toggle button to the right */
.navbar .navbar-brand {
  margin-right: auto;
}

.navbar .navbar-toggler {
  border-color: transparent;
  margin-left: auto;
}

/* Style the navbar links */
.navbar-nav .nav-link {
  color: rgb(0, 0, 0) !important;
  font-size: 1.1em;
  margin-left: 10px;
}

/* Center navbar items when collapsed */
@media (max-width: 768px) {
  .navbar-collapse {
    text-align: center;
  }
}

.navbar .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar img {
  height: 50px;
}

.navbar ul {
  list-style-type: none;
  display: flex;
}

.navbar li {
  margin-left: 20px;
}

.navbar a {
  color: #ffffff;
  text-decoration: none;
  font-size: 1.1em;
}

.hero {
  position: relative;
  text-align: center;
  color: white;
}

.hero-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.hero-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.6);
  padding: 20px;
  border-radius: 10px;
  max-width: 90%;
  text-align: center;
}

.hero-overlay h2 {
  margin-bottom: 10px;
}

.btn-primary,
.btn-secondary {
  background-color: #2575fc;
  border: none;
  padding: 10px 20px;
  color: white;
  font-size: 1.1em;
  border-radius: 5px;
  cursor: pointer;
}

.features {
  background: white;
  padding: 20px;
  text-align: center;
}

.features h2 {
  margin-bottom: 20px;
}

.features-list {
  list-style-type: none;
  padding: 0;
}

.feature-icon {
  margin-right: 8px;
}

.testimonials {
  background: #f3f3f3;
  padding: 20px;
  text-align: center;
}

.testimonials h2 {
  margin-bottom: 20px;
}

.testimonial {
  margin: 10px 0;
}

.screenshots {
  background: white;
  padding: 20px;
}

.screenshots h2 {
  text-align: center;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
  justify-items: center;
}

.gallery-item img {
  width: 100%;
  border-radius: 10px;
}

.contact {
  background: white;
  padding: 20px;
}

.contact h2 {
  text-align: center;
}

.contact form {
  max-width: 600px;
  margin: 0 auto;
}

.contact label {
  display: block;
  margin: 10px 0 5px;
}

.contact input,
.contact textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

.contact button {
  background: #2575fc;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.error {
  color: red;
  font-size: 0.9em;
}

.confirmation {
  color: green;
  font-size: 0.9em;
}

footer {
  background: linear-gradient(90deg, #6a11cb, #2575fc);
  color: white;
  text-align: center;
  padding: 15px;
}

.footer-links {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.footer-links li {
  margin: 0 10px;
}

.footer-links a {
  color: #ffffff;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

.contact-form {
  max-width: 500px;
  margin: 0 auto;
}

.contact button {
  padding: 10px 30px;
  border-radius: 50px;
  background-color: #ff5f6d;
  color: white;
  border: none;
  font-size: 1.1em;
}

.form-group {
  margin-bottom: 1rem;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 5px;
}

.btn-primary {
  background-color: #2575fc;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.error {
  color: red;
  font-size: 0.9em;
  margin-top: 5px;
}

.confirmation {
  color: green;
  font-size: 0.9em;
  margin-top: 10px;
}

/* Center logo in Navbar */
.navbar-custom .navbar-brand {
  display: flex;
  justify-content: center;
  width: 100%;
}

/* Style for navbar links and mobile view */
.navbar-nav .nav-link {
  color: #ffffff !important;
  font-size: 1.1em;
}

.navbar-toggler {
  border-color: #ffffff;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

@media (max-width: 768px) {
  .navbar-collapse {
    text-align: center;
  }
}

/* Navbar styling for alignment */
.navbar-custom {
  background: linear-gradient(90deg, #6a11cb, #2575fc);
  padding: 15px;
}

.navbar-custom .navbar-brand .logo {
  height: 50px;
}

.navbar-custom .navbar-toggler {
  border-color: transparent;
}

.navbar-custom .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-custom .nav-link {
  color: white !important;
  font-size: 1.1em;
  margin-left: 10px;
}

@media (max-width: 768px) {
  .navbar-custom .navbar-collapse {
    text-align: center;
  }
}

.logo {
  max-height: 50px;
  width: auto;
}


.button {
  padding: 12px 40px;
  border-radius: 50px;
  background: linear-gradient(135deg, #ff5f6d, #ffc371);
  color: white;
  font-size: 1.2em;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 8px 15px rgba(255, 95, 109, 0.3);
  display: inline-block;
  text-decoration: none;
}

/* Hover effect */
.button:hover {
  background: linear-gradient(135deg, #ff71f6, #ef5fff);
  box-shadow: 0 12px 20px rgba(255, 95, 109, 0.4);
  transform: translateY(-3px);
}

/* Active effect for click */
.button:active {
  transform: scale(0.98);
  box-shadow: 0 5px 10px rgba(255, 95, 109, 0.2);
}


.game-features {
  background: linear-gradient(135deg, #6e45e2, #88d3ce);

  border-radius: 15px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
  color: white;
  margin: 2rem 1rem;
  position: relative;
  overflow: hidden;
}

.game-features h2 {
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}

.features-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.features-list li {
  font-size: 1rem;
  line-height: 1;
  margin: 1rem 0;
  font-weight: bold;
  position: relative;

  /* Space for icon */
}

/* Floating Shapes for Decoration */
.floating-shape {
  position: absolute;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
}


/* Floating animation for decorative shapes */
@keyframes float {
  0% {
    transform: translateY(0) rotate(0deg);
  }

  50% {
    transform: translateY(-20px) rotate(180deg);
  }

  100% {
    transform: translateY(0) rotate(360deg);
  }
}

.game-description {
  background: linear-gradient(135deg, #6e45e2, #88d3ce);
  border-radius: 15px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
  color: white;
  margin: 2rem 1rem;
  position: relative;
  overflow: hidden;
}


.power-up {
  font-weight: bold;
}

.power-up.heart {
  color: #000000;
}

.power-up.points {
  color: #000000;
}

/* Floating Shapes for Decoration */
.floating-shape {
  position: absolute;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
}

.shape1 {
  top: -20px;
  left: -20px;
  width: 80px;
  height: 80px;
  animation: float 6s infinite ease-in-out;
}

.shape2 {
  bottom: -30px;
  right: -30px;
  width: 100px;
  height: 100px;
  animation: float 8s infinite ease-in-out;
}

/* Floating animation for decorative shapes */
@keyframes float {
  0% {
    transform: translateY(0) rotate(0deg);
  }

  50% {
    transform: translateY(-20px) rotate(180deg);
  }

  100% {
    transform: translateY(0) rotate(360deg);
  }

}

.screenshots {
  background: linear-gradient(135deg, #6e45e2, #88d3ce);

  border-radius: 15px;
  text-align: center;
  color: rgb(255, 255, 255);
  margin: 2rem 1rem;
  position: relative;

}

.screenshots h2 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #ffffff;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
}

/* Grid Gallery Styling for larger screens */
.gallery {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
  max-width: 100%;
}

/* Hide grid layout on mobile and show carousel */
@media (max-width: 768px) {
  .gallery-container {
    display: none;
    /* Hide gallery grid layout on mobile */
  }

  .carousel-container {
    display: block;
    /* Show carousel on mobile */
  }

  .carousel-container img {
    width: 80%;
    /* Scale down images for mobile */
    max-width: 260px;
    /* Set max-width to limit size on small screens */
    height: auto;
    /* Keep the aspect ratio */
    margin: 0 auto;
    /* Center images */
  }
}

/* Gallery styling for desktop and larger screens */
@media (min-width: 769px) {
  .carousel-container {
    display: none;
    /* Hide carousel on larger screens */
  }
}

/* Floating Shapes for Decoration */
.floating-shape {
  position: absolute;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
}

.shape1 {
  top: -20px;
  left: -20px;
  width: 100px;
  height: 100px;
  animation: float 6s infinite ease-in-out;
}

.shape2 {
  bottom: -30px;
  right: -30px;
  width: 120px;
  height: 120px;
  animation: float 8s infinite ease-in-out;
}


/* Floating animation for decorative shapes */
@keyframes float {
  0% {
    transform: translateY(0) rotate(0deg);
  }

  50% {
    transform: translateY(-20px) rotate(180deg);
  }

  100% {
    transform: translateY(0) rotate(360deg);
  }
}

/* Base styles for larger screens (e.g., desktops, tablets) */
.game-description,
.game-features {
  padding: 2rem;
  text-align: center;
  /* Ensures the text is centered horizontally */
  margin-bottom: 2rem;
  display: flex;
  /* Flexbox for alignment */
  flex-direction: column;
  /* Stack content vertically */
  justify-content: center;
  /* Centers the content vertically */
  align-items: center;
  /* Centers the content horizontally */
}

h2 {
  font-size: 1.2rem;
  /* Larger headings for larger screens */
  margin-bottom: 1rem;
}

p {
  font-size: 1.2rem;
  /* Adjust paragraph size for better readability */
  line-height: 1.6;
  text-align: center;
  /* Ensure paragraphs are centered */
  margin-bottom: 1.5rem;
}

.features-list {
  padding-left: 1.5rem;
  text-align: center;
  /* Center the list items */
  margin-top: 1rem;
}

.features-list li {
  font-size: 1.1rem;
  line-height: 1.4;
  text-align: center;
}

.floating-shape {
  position: absolute;
  animation: float 5s ease-in-out infinite;
}

.shape1 {
  top: 10%;
  left: 20%;
}

.shape2 {
  top: 50%;
  right: 15%;
}

/* Media Query for Tablets and Mobile Devices */
@media (max-width: 768px) {

  .game-description,
  .game-features {
    padding: 1rem;
    /* Reduce padding on mobile */
    margin-bottom: 1rem;
    /* Reduce margin to make sections more compact */
  }

  h2 {
    font-size: 1.8rem;
    /* Further reduce font size for headings on tablets and smaller screens */
    margin-bottom: 0.5rem;
    /* Reduced bottom margin */
  }

  p {
    font-size: 1rem;
    /* Much smaller text for better mobile readability */
    line-height: 1.4;
    /* Adjust line height for better readability */
    margin-bottom: 1rem;
    /* Reduce space between paragraph and next element */
  }

  .features-list {
    padding-left: 1rem;
    /* Adjust padding */
  }

  .features-list li {
    font-size: 0.95rem;
    /* Smaller font size for mobile */
  }

  /* Scale down the floating shapes */
  .floating-shape {
    width: 35px;
    height: 35px;
  }

  .shape1 {
    top: 5%;
    left: 10%;
  }

  .shape2 {
    top: 40%;
    right: 10%;
  }
}

/* Further scaling for very small devices like phones in portrait mode */
@media (max-width: 480px) {

  .game-description,
  .game-features {
    padding: 0.5rem;
    /* Even smaller padding for very small devices */
    margin-bottom: 1rem;
  }

  h2 {
    font-size: xx-small;
    /* Further reduce heading size for very small screens */
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 0.9rem;
    /* Even smaller paragraph font size */
    line-height: 1.3;
    margin-bottom: 0.5rem;
    /* Reduce bottom margin */
  }

  .features-list {
    padding-left: 0.5rem;
    /* Further reduce padding */
  }

  .features-list li {
    font-size: 0.85rem;
    /* Smaller list item font size */
  }

  /* Even smaller floating shapes */
  .floating-shape {
    width: 25px;
    height: 25px;
  }

  .shape1 {
    top: 5%;
    left: 5%;
  }

  .shape2 {
    top: 35%;
    right: 5%;
  }
}